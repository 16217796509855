import axios from 'axios'
import moment from 'moment'

export default {

    namespaced: true,

    state:{
        retiradas: [],
        tipo_data: 1,
        data_inicio: moment().startOf("month").format("YYYY-MM-DD"),
        data_final: moment().endOf("month").format("YYYY-MM-DD"),
        cliente: 0,
        busy: false,
        pedidosPendentes: 0
    },

    mutations:{
        STORE_RETIRADAS(state, result){
            state.retiradas = result
        },

        DELETAR_RETIRADA(state, index){
            state.retiradas.splice(index, 1)
        },

        ADICIONAR_RETIRADA(state, result) {
            state.retiradas.push(result)
        },

        EDITAR_RETIRADA(state, response) {
            state.retiradas.splice(response.index, 1, response.dados)
        },

        STORE_TIPO_DATA(state, result) {
          state.tipo_data = result
        },

        STORE_FILTRO_DATA(state, payload) {
          state.data_inicio = payload.data_inicio
          state.data_final = payload.data_final
        },

        STORE_RETIRADAS_CLIENTES(state, result) {
          state.cliente = result
        },

        STORE_PEDIDOS_PENDENTES(state, result) {
          state.pedidosPendentes = result
        },

        STORE_BUSY(state, result) {
          state.busy = result
        }

    },

    actions:{

        listaRetiradas(context){
            context.commit('STORE_BUSY', true)

            axios('adm/retiradas' + '?tipo_data=' + context.state.tipo_data + '&data_inicio=' + context.state.data_inicio + '&data_final=' + context.state.data_final + '&cliente=' + context.state.cliente).then(res => {
                context.commit("STORE_RETIRADAS", res.data.retiradas)
                context.commit("STORE_PEDIDOS_PENDENTES", res.data.vencendo)
                context.commit('STORE_BUSY', false)
            })
        }

    }


}
