import axios from 'axios'
import Vue from 'vue'
import getDate from '../../services/data'
import moment from 'moment'

export default {

  namespaced: true,

  state: {
    receitas: [],
    filtroStatusReceita: 0,
    filtroDataInicial: moment().startOf("month").format("YYYY-MM-DD"),
    filtroDataFinal: moment().endOf("month").format("YYYY-MM-DD"),
    receitasFiltradas: [],
    receitasFiltradasData: [],
    totalAReceber: 0,
    totalAtrasados: 0,
    totalRecebidos: 0,
    filtroCliente: 0,
    busy: false
  },

  mutations: {

    STORE_RECEITAS(state, payload) {
      state.receitas = payload.receitas
      state.totalAReceber = payload.total_receber
      state.totalAtrasados = payload.total_atrasados
      state.totalRecebidos = payload.total_recebido
    },

    DELETAR_RECEITA(state, index) {
      state.receitas.splice(index, 1)
    },

    ADICIONAR_RECEITA(state, payload) {
      state.receitas.push(payload)
    },

    EDITAR_RECEITA(state, response) {
      let index = state.receitas.findIndex(v => v.id == response.id);
      state.receitas.splice(index, 1, response)
    },

    STORE_RECEITAS_FILTRADAS(state, result) {
      state.filtroStatusReceita = result
    },

    STORE_RECEITAS_FILTRADAS_DATAS(state, result) {
      state.filtroDataInicial = result.data_inicio
      state.filtroDataFinal = result.data_final
    },

    STORE_RECEITAS_CLIENTES(state, result) {
      state.filtroCliente = result
    },

    STORE_BUSY(state, result) {
      state.busy = result
    }

  },

  /* getters: {
    receitasGetters(state) {
      let resultado = state.receitas
      let cardAReceber = 100
      let cardAtrasados = 200
      let cardRecebidos = 50

      if (state.filtroStatusReceita !== 0) {
        if (state.filtroStatusReceita === 1) {
          resultado = resultado.filter(res => res.data_recebimento === null)
        } else resultado = resultado.filter(res => res.data_recebimento !== null)

      }
      if (state.filtroDataInicial !== '' && state.filtroDataFinal !== '') {
        let dataInicial = Date.parse(state.filtroDataInicial);
        let dataFinal = Date.parse(state.filtroDataFinal);


        resultado = resultado.filter((res) => {
          return Date.parse(res.data_lancamento) >= dataInicial && Date.parse(res.data_lancamento) <= dataFinal
        })
      }

      let resultadoAReceber = resultado.filter((res) => {
        return res.data_recebimento === null
      })
      cardAReceber = resultadoAReceber.reduce((total, item) => total + Number(item.valor), 0);
      cardAReceber = cardAReceber.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

      let resultadoAtrasados = resultado.filter((res) => {
        return (Date.parse(res.data_lancamento) < Date.parse(getDate.hoje()) && res.data_recebimento === null)
      })
      cardAtrasados = resultadoAtrasados.reduce((total, item) => total + Number(item.valor), 0);
      cardAtrasados = cardAtrasados.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

      let resultadoRecebidos = resultado.filter((res) => {
        return res.data_recebimento !== null
      })
      cardRecebidos = resultadoRecebidos.reduce((total, item) => total + Number(item.valor_recebido), 0);
      cardRecebidos = cardRecebidos.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });




      return { resultado, cardAReceber, cardAtrasados, cardRecebidos }
    }
  }, */

  actions: {
    listaReceitas(context) {
      context.commit('STORE_BUSY', true)
      axios(`adm/receitas/?cliente=${context.state.filtroCliente}&data_inicio=${context.state.filtroDataInicial}&data_final=${context.state.filtroDataFinal}`).then(res => {
        context.commit("STORE_RECEITAS", res.data)
        context.commit('STORE_BUSY', false)
      })
    }
  }
}
