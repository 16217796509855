import axios from 'axios'

export default {

  namespaced: true,

  state: {
    dfcDiario: [],
    dfcMensal: [],
    dre: [],
  },

  getters: {
    tabelaDfcMensal: state => {
      let valores = [];
      for (let i = 0; i < 12; i++) {
        valores.push({
          inicioPrevisto: 0,
          inicioRealizado: 0,
          entradaPrevista: 0,
          entradaRealizada: 0,
          saidaPrevista: 0,
          saidaRealizada: 0,
          finalPrevisto: 0,
          finalRealizado: 0,
        });
      }

      valores[0].inicioPrevisto = state.dfcMensal.InicioPrevisto;
      valores[0].inicioRealizado = state.dfcMensal.InicioRealizado;

      if (state.dfcMensal.entradas) {
        for (let i = 0; i < 12; i++) {
          if (state.dfcMensal.entradas[i + 1]?.previsto)
            valores[i].entradaPrevista =
              state.dfcMensal.entradas[i + 1].previsto;
          else valores[i].entradaPrevista = 0;

          if (state.dfcMensal.entradas[i + 1]?.realizado)
            valores[i].entradaRealizada =
              state.dfcMensal.entradas[i + 1].realizado;
          else valores[i].entradaRealizada = 0;
        }
      }

      if (state.dfcMensal.saidas) {
        for (let i = 0; i < 12; i++) {
          if (state.dfcMensal.saidas[i + 1]?.previsto)
            valores[i].saidaPrevista =
              state.dfcMensal.saidas[i + 1].previsto;
          else valores[i].saidaPrevista = 0;

          if (state.dfcMensal.saidas[i + 1]?.realizado)
            valores[i].saidaRealizada =
              state.dfcMensal.saidas[i + 1].realizado;
          else valores[i].saidaRealizada = 0;
        }
      }

      valores[0].finalPrevisto =
        Number(valores[0].inicioPrevisto) +
        Number(valores[0].entradaPrevista) -
        Number(valores[0].saidaPrevista);
      valores[0].finalRealizado =
        Number(valores[0].inicioRealizado) +
        Number(valores[0].entradaRealizada) -
        Number(valores[0].saidaRealizada);

      for (let i = 1; i < 12; i++) {
        valores[i].inicioPrevisto = valores[i - 1].finalPrevisto;
        valores[i].inicioRealizado =
          valores[i - 1].finalRealizado;

        valores[i].finalPrevisto =
          Number(valores[i].inicioPrevisto) +
          Number(valores[i].entradaPrevista) -
          Number(valores[i].saidaPrevista);
        valores[i].finalRealizado =
          Number(valores[i].inicioRealizado) +
          Number(valores[i].entradaRealizada) -
          Number(valores[i].saidaRealizada);
      }
      return valores;
    },

    receitaOperacionalBruta: state => {
      let valores = [];
      for (let i = 0; i < 12; i++) {
        valores.push(0);
      }

      if (state.dre.receitas) {
        for (let i = 0; i < 12; i++) {
          if (state.dre.receitas[i + 1])
            valores[i] = Number(state.dre.receitas[i + 1]);
          else
            valores[i] = 0;
        }
      }
      return valores;
    },

    pagImpostos: state => {
      let valores = [];
      for (let i = 0; i < 12; i++) {
        valores.push(0);
      }

      if (state.dre.despesas) {
        for (let i = 0; i < 12; i++) {
          if (state.dre.despesas[i + 1] && state.dre.despesas[i + 1][1])
            valores[i] = Number(state.dre.despesas[i + 1][1]);
          else
            valores[i] = 0;
        }
      }
      return valores;
    },

    receitaOperacionalLiquida: (state, getters) => {
      let valores = [];
      for (let i = 0; i < 12; i++) {
        valores.push(getters.receitaOperacionalBruta[i] - getters.pagImpostos[i]);
      }
      return valores;
    },

    custosFixos: state => {
      let valores = [];
      for (let i = 0; i < 12; i++) {
        valores.push(0);
      }

      if (state.dre.despesas) {
        for (let i = 0; i < 12; i++) {
          if (state.dre.despesas[i + 1] && state.dre.despesas[i + 1][2])
            valores[i] = Number(state.dre.despesas[i + 1][2]);
          else
            valores[i] = 0;
        }
      }
      return valores;
    },

    custosVariaveis: state => {
      let valores = [];
      for (let i = 0; i < 12; i++) {
        valores.push(0);
      }

      if (state.dre.despesas) {
        for (let i = 0; i < 12; i++) {
          if (state.dre.despesas[i + 1] && state.dre.despesas[i + 1][3])
            valores[i] = Number(state.dre.despesas[i + 1][3]);
          else
            valores[i] = 0;
        }
      }
      return valores;
    },

    despesas: state => {
      let valores = [];
      for (let i = 0; i < 12; i++) {
        valores.push(0);
      }

      if (state.dre.despesas) {
        for (let i = 0; i < 12; i++) {
          if (state.dre.despesas[i + 1] && state.dre.despesas[i + 1][4])
            valores[i] = Number(state.dre.despesas[i + 1][4]);
          else
            valores[i] = 0;
        }
      }
      return valores;
    },

    resultadoOperacional: (state, getters) => {
      let valores = [];
      for (let i = 0; i < 12; i++) {
        valores.push(getters.receitaOperacionalBruta[i] - getters.pagImpostos[i] // getters.receitaOperacionalLiquida[i]
          - getters.custosFixos[i] - getters.custosVariaveis[i] - getters.despesas[i]);
      }
      return valores;
    },

    despesasNaoOperacionaisInvestimentos: state => {
      let valores = [];
      for (let i = 0; i < 12; i++) {
        valores.push(0);
      }

      if (state.dre.despesas) {
        for (let i = 0; i < 12; i++) {
          if (state.dre.despesas[i + 1] && state.dre.despesas[i + 1][5])
            valores[i] = Number(state.dre.despesas[i + 1][5]);
          else
            valores[i] = 0;
        }
      }
      return valores;
    },

    lair: (state, getters) => {
      let valores = [];
      for (let i = 0; i < 12; i++) {
        valores.push(getters.receitaOperacionalBruta[i] - getters.pagImpostos[i] // getters.receitaOperacionalLiquida[i]
          - getters.custosFixos[i] - getters.custosVariaveis[i] - getters.despesas[i] // getters.resultadoOperacional[i]
          - getters.despesasNaoOperacionaisInvestimentos[i]
        );
      }
      return valores;
    },

    irpjCsll: state => {
      let valores = [];
      for (let i = 0; i < 12; i++) {
        valores.push(0);
      }

      if (state.dre.despesas) {
        for (let i = 0; i < 12; i++) {
          if (state.dre.despesas[i + 1] && state.dre.despesas[i + 1][6])
            valores[i] = Number(state.dre.despesas[i + 1][6]);
          else
            valores[i] = 0;
        }
      }
      return valores;
    },
    lucroLiquido: (state, getters) => {
      let valores = [];
      for (let i = 0; i < 12; i++) {
        valores.push(getters.receitaOperacionalBruta[i] - getters.pagImpostos[i] // getters.receitaOperacionalLiquida[i]
          - getters.custosFixos[i] - getters.custosVariaveis[i] - getters.despesas[i] // getters.resultadoOperacional[i]
          - getters.despesasNaoOperacionaisInvestimentos[i] // getters.lair[i]
          - getters.irpjCsll[i]
        );
      }
      return valores;
    },
  },

  mutations: {
    STORE_DFC_DIARIO(state, result) {
      state.dfcDiario = result
    },

    STORE_DFC_MENSAL(state, result) {
      state.dfcMensal = result
    },

    STORE_DRE(state, result) {
      state.dre = result
    },
  },

  actions: {
    buscaDfcDiario(context, payload) {
      let complemento = '';
      if (payload.conta_id)
        complemento = '&conta_id=' + payload.conta_id;
      axios('adm/relatorios/dfc_diario?data_inicio=' + payload.data_inicio + '&data_final=' + payload.data_final + complemento).then((res) => {
        context.commit('STORE_DFC_DIARIO', res.data)
      })
    },

    buscaDfcMensal(context, payload) {
      let complemento = '';
      if (payload.conta_id)
        complemento = '?conta_id=' + payload.conta_id;
      axios('adm/relatorios/dfc_mensal/' + payload.ano + complemento).then((res) => {
        context.commit('STORE_DFC_MENSAL', res.data)
      })
    },

    buscaDre(context, payload) {
      axios('adm/relatorios/dre/' + payload.ano).then((res) => {
        context.commit('STORE_DRE', res.data)
      })
    },
  }
}
