import Cookie from './../services/cookie'
import axios from './../libs/axios'
import store from './../store'
import permissoes from "@/services/permissoes";

export default {

    redirectIfAuthenticated(to, from, next){

        const token = Cookie.getToken()
        let n;

        if(token){
            n = ({ name: 'home'})
        }

        next(n)

    },


    async redirectIfNotAuthenticated(to, from, next){

        const token = Cookie.getToken()

        if(!token){
            next( { name: 'login'})
        }else{
            await axios.get('adm/me').then(res => {

                if(Number(res.status) === 200){
                    if(!store?.state?.user?.user?.id){
                        store.commit('user/STORE_USER', res.data.data)
                    }
                }

                if(!permissoes.guardNavigation(res.data.data.adm_permissoes, to.meta.permissoes)){
                    next( { name: 'usuario-nao-habilitado'})
                }

            }).catch(() => {
                Cookie.deleteToken()
                next( { name: 'login'})
            })
            next()
        }

    },



}



