import axios from 'axios'

export default {

    namespaced: true,

    state:{
        assinaturas: []
    },

    mutations:{
        STORE_ASSINATURAS(state, result){
            state.assinaturas = result
        },

        ADICIONAR_ASSINATURAS(state, result) {
          state.assinaturas.push(result)
        },

        DELETAR_ASSINATURAS(state, index){
            state.assinaturas.splice(index, 1)
        },
       
        EDITAR_ASSINATURAS(state, response) {
            state.assinaturas.splice(response.index, 1, response.dados)
        },

    },

    actions:{

      listaAssinaturas(context){
          axios('adm/assinaturas').then(res => {
              context.commit("STORE_ASSINATURAS", res.data)
          })
      }

  }

}