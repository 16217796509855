import Vue from 'vue'
import Cookie from 'js-cookie'
import baseUrl from "@/services/baseUrl";
// axios
import axios from 'axios'

axios.defaults.baseURL = `${baseUrl}/api/v1`
axios.defaults.baseWebURL = baseUrl
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

axios.interceptors.request.use(function(config){

    const token = Cookie.get('token')

    if(token){
        config.headers.common['Authorization'] = `Bearer ${token}`;
    }

    return config;

})

Vue.prototype.$http = axios

export default axios
