
export default [

    {
        path: '/erros/usuario-nao-habilitado', name: 'usuario-nao-habilitado',
        component: () => import('@/views/error/usuarioNaoHabilitado.vue'),
        meta: {pageTitle: 'Acesso Negado', permissoes: 0},
    },

]
