import axios from 'axios'
export default {

    namespaced: true,

    state:{
        user:[],
        acl: [],
        errors_user: false,
    },

    mutations:{
        STORE_USER(state, user){
            state.user = user
        },
        STORE_USER_ERROR_LOGIN(state, error){
            state.errors_user = error
        },

        UPDATE_ACL(state, acl) {
            state.acl = acl
        }

    },

    getters: {
        userPermissions(state) {
            return state.user.adm_permissoes;
        }
    },

    actions: {
        listaUser(context){  
            axios('adm/me').then(res => {
                context.commit("STORE_USER", res.data.data)
            })
        }
    }

}
