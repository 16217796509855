import axios from 'axios'

export default {

    namespaced: true,

    state: {
        planos: [],
    },

    getters: {
        planoIdServicos: state => {
            if (state.planos.length > 0)
                if (state.planos[0].filhos?.length > 0)
                    if (state.planos[0].filhos[0].filhos?.length > 0)
                        if (state.planos[0].filhos[0].filhos[0])
                            return state.planos[0].filhos[0].filhos[0].id;
            return null;
        },
        planoIdOutrasReceitas: state => {
            if (state.planos.length > 0)
                if (state.planos[0].filhos?.length > 0)
                    if (state.planos[0].filhos[2].filhos?.length > 0)
                        if (state.planos[0].filhos[2].filhos[0])
                            return state.planos[0].filhos[2].filhos[0].id;
            return null;
        },
        planosDevedores: state => {
            if (state.planos.length > 1)
                if (state.planos[1].filhos?.length > 0)
                    return state.planos[1].filhos;
            return [];
        },
        planosCredores: state => {
            if (state.planos.length > 0)
                if (state.planos[0].filhos?.length > 0)
                    return state.planos[0].filhos;
            return [];
        },
        planosAgrupados: state => {
            var novoArray = [];

            for (const planoN0 of state.planos) {
                novoArray[planoN0.id] = planoN0.nome;
                if (planoN0.filhos?.length > 0)
                    for (const planoN1 of planoN0.filhos) {
                        novoArray[planoN1.id] = planoN1.nome;
                        if (planoN1.filhos?.length > 0)
                            for (const planoN2 of planoN1.filhos) {
                                novoArray[planoN2.id] = planoN2.nome;
                                if (planoN1.filhos?.length > 0)
                                    for (const planoN3 of planoN2.filhos) {
                                        novoArray[planoN3.id] = planoN3.nome;
                                    }
                            }
                    }
            }
            return novoArray;

        },
        despesasAgrupadas: (state, getters) => {
            var novoArray = [];
            for (const plano of getters.planosDevedores) {
                novoArray.push({ id: plano.id, nome: plano.nome, dre_id: plano.dre_id, posicao: 0, ordem: plano.filhos?.length });
                if (plano.filhos?.length > 0)
                    for (const sub of plano.filhos) {
                        novoArray.push({ id: sub.id, nome: sub.nome, dre_id: sub.dre_id, posicao: 1, ordem: sub.filhos?.length });
                        if (sub.filhos?.length > 0)
                            for (const subsub of sub.filhos) {
                                novoArray.push({ id: subsub.id, nome: subsub.nome, dre_id: subsub.dre_id, posicao: 2, ordem: subsub.filhos?.length });
                            }
                    }
            }
            return novoArray;
        },
        receitasAgrupadas: (state, getters) => {
            var novoArray = [];
            for (const plano of getters.planosCredores) {
                novoArray.push({ id: plano.id, nome: plano.nome, dre_id: plano.dre_id, posicao: 0, ordem: plano.filhos?.length });
                if (plano.filhos?.length > 0)
                    for (const sub of plano.filhos) {
                        novoArray.push({ id: sub.id, nome: sub.nome, dre_id: sub.dre_id, posicao: 1, ordem: sub.filhos?.length });
                        if (sub.filhos?.length > 0)
                            for (const subsub of sub.filhos) {
                                novoArray.push({ id: subsub.id, nome: subsub.nome, dre_id: subsub.dre_id, posicao: 2 , ordem: subsub.filhos?.length});
                            }
                    }
            }
            return novoArray;
        },
    },

    mutations: {
        STORE_PLANOS(state, result) {
            state.planos = result
        },

        DELETAR_PLANO(state, response) {
            if (response.idxN3 > -1)
                state.planos[response.idxN0].filhos[response.idxN1].filhos[response.idxN2].filhos.splice(response.idxN3, 1)
            else if (response.idxN2 > -1) {
                state.planos[response.idxN0].filhos[response.idxN1].filhos.splice(response.idxN2, 1)
            } else if (response.idxN1 > -1)
                state.planos[response.idxN0].filhos.splice(response.idxN1, 1)
            // else if (response.idxN0 > -1)
            //    state.planos.splice(response.idxN0, 1)
        },

        ADICIONAR_PLANO(state, result) {
            if (result.idxN2 > -1) {
                state.planos[result.idxN0].filhos[result.idxN1].filhos[result.idxN2].filhos.push(result.dados)
            } else if (result.idxN1 > -1) {
                state.planos[result.idxN0].filhos[result.idxN1].filhos.push(result.dados)
            } else if (result.idxN0 > -1) {
                state.planos[result.idxN0].filhos.push(result.dados)
            }
            // else
            //   state.planos.push(result.dados)
        },

        EDITAR_PLANO(state, response) {
            if (response.idxN3 > -1)
                state.planos[response.idxN0].filhos[response.idxN1].filhos[response.idxN2].filhos.splice(response.idxN3, 1, response.dados)
            else if (response.idxN2 > -1)
                state.planos[response.idxN0].filhos[response.idxN1].filhos.splice(response.idxN2, 1, response.dados)
            else if (response.idxN1 > -1)
                state.planos[response.idxN0].filhos.splice(response.idxN1, 1, response.dados)
            // else if (response.idxN0 > -1)
            //    state.planos.splice(response.idxN0, 1, response.dados)
        },

    },

    actions: {
        listaPlanos(context) {
            axios('adm/planos').then(res => {
                context.commit("STORE_PLANOS", res.data)
            })
        }

    }

}
