import axios from 'axios'
import Vue from 'vue'
import getDate from '../../services/data'

export default {

  namespaced: true,

  state: {
    contas: [],
  },

  mutations: {

    STORE_CONTAS(state, payload) {
      state.contas = payload
    },

    DELETAR_CONTA(state, index) {
      state.contas.splice(index, 1)
    },

    ADICIONAR_CONTA(state, payload) {
      state.contas.push(payload)
    },

    EDITAR_CONTA(state, response) {
      state.contas.splice(response.index, 1, response.dados)
    },

  },

  actions: {
    listaContas(context) {
      axios('adm/contas').then(res => {
        context.commit("STORE_CONTAS", res.data)
    })
    }
  }
}
