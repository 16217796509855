import axios from "axios"

export default {
  namespaced: true,

  state: {
    migracao: []
  },

  mutations: {
    STORE_MIGRACAO(state, result) {
        state.migracao = result
    }
  },

  actions: {
    listaMigracao(context, id) {
      axios('adm/administracao/clientes/migracao/'+id).then(res => {
        context.commit("STORE_MIGRACAO", res.data)
      })
    }
  }
  
}
