import axios from 'axios'

export default {

  namespaced: true,

  state: {
    retiradas: [],
  },

  mutations: {
    STORE_RETIRADAS(state, result) {
      state.retiradas = result
    },

    DELETAR_RETIRADA(state, index) {
      state.retiradas.splice(index, 1)
    },

    ADICIONAR_RETIRADA(state, result) {
      state.retiradas.push(result)
    },

    EDITAR_RETIRADA(state, response) {
      state.retiradas.splice(response.index, 1, response.dados)
    }

  },

  actions: {

    listaPedidosRetirada(context) {
      axios('adm/retiradas/dashboard').then(res => {
        context.commit("STORE_RETIRADAS", res.data)
      })
    }

  }


}
