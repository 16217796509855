import axios from 'axios'
export default {

    namespaced: true,

    state:{
        usuarios:[],
        page: 1,
        filtro: '',
    },

    mutations:{
        STORE_USUARIOS(state, usuarios){
            state.usuarios = usuarios
        },
        STORE_PAGINACAO(state, page){
            state.page = page
        },
        STORE_BUSCA_EMAIL(state, filtro){
            state.filtro = filtro
        },

    },

    actions: {
        listaUsuarios(context){  
            axios('adm/administracao/usuarios-cliente/?page='+context.state.page +'&email='+context.state.filtro).then(res => {
                context.commit("STORE_USUARIOS", res.data)
            })
        }
    }

}
