import Vue from 'vue'
import VueRouter from 'vue-router'
import administracao from './administracao'
import auth from './auth'
import cadastros from './cadastros'
import financeiro from './financeiro'
import home from './home'
import usuarios from './usuarios'
import errors from "@/router/errors"
import mensagens from './mensagens'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...auth,
    ...home,
    ...usuarios,
    ...cadastros,
    ...administracao,
    ...financeiro,
    ...mensagens,
    ...errors,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        permissoes: 0
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
