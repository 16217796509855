import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import user from './user'
import planos from './planos'
import assinaturas from './assinaturas'
import fornecedores from './fornecedores'
import despesas from './despesas'
import receitas from './receitas'
import empresas from './empresas'
import contas from './contas'
import cupons from './cupons'
import clientes from './clientes'
import retiradas from './retiradas'
import atendimento from './atendimento'
import dashboard_atendimento from './dashboard-atendimento'
import dashboard_retiradas from './dashboard-retiradas'
import cadastro_teste from './cadastro-teste'
import usuarios_cliente from './usuarios-cliente'
import migracao from './migracao'
import relatorios from './relatorios'
import dashboard_teste_gratis_x_assinantes from './dashboard-teste-gratis-x-assinantes'
import mensagens from './mensagens'
import nota_fiscal from './nota-fiscal'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    user,
    planos,
    assinaturas,
    fornecedores,
    despesas,
    receitas,
    empresas,
    contas,
    cupons,
    clientes,
    retiradas,
    atendimento,
    dashboard_atendimento,
    dashboard_retiradas,
    cadastro_teste,
    usuarios_cliente,
    migracao,
    relatorios,
    dashboard_teste_gratis_x_assinantes,
    mensagens,
    nota_fiscal
  },
  strict: process.env.DEV,
})
