
import axios from 'axios'

export default {

    namespaced: true,

    state: {
        empresas: []
    },

    mutations: {
        STORE_EMPRESAS(state, result){
            state.empresas = result
        }
    },

    actions:{

        listaEmpresas(context){
            axios('adm/empresas').then(res => {
                context.commit("STORE_EMPRESAS", res.data)
            })
        }

    }

}
