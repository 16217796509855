import axios from 'axios'

export default {

    namespaced: true,

    state:{
        fornecedores: []
    },

    mutations:{
        STORE_FORNECEDORES(state, result){
            state.fornecedores = result
        },

        DELETAR_FORNECEDOR(state, index){
            state.fornecedores.splice(index, 1)
        },

        ADICIONAR_FORNECEDOR(state, result) {
            state.fornecedores.push(result)
        },

        EDITAR_FORNECEDOR(state, response) {
            state.fornecedores.splice(response.index, 1, response.dados)
        },

    },

    getters: {
        fornecedoresAgrupados: state => {

          let forn = {}

          state.fornecedores.forEach( v => forn[v.id] = v)

          return forn

        }
    },


    actions:{

        listaFornecedores(context){
            axios('adm/fornecedores').then(res => {
                context.commit("STORE_FORNECEDORES", res.data)
            })
        }

    }


}
