import axios from 'axios'
import moment from 'moment'

export default {

    namespaced: true,

    state: {
        clientes: [],
        total_gratis: 0,
        total_mensal: 0,
        total_anual: 0,
        total_assinantes: 0,
        tipo_data: 1,
        tipo_conta: 0,
        tipo_busca: 'ID',
        input_busca: '',
        data_inicio: moment().startOf("month").format("YYYY-MM-DD"),
        data_final: moment().endOf("month").format("YYYY-MM-DD"),
        busy: false,
        page: 1
    },

    mutations: {
        STORE_CLIENTES(state, result) {
            state.clientes = result.clientes
            state.total_gratis = result.total_gratis
            state.total_mensal = result.total_mensal
            state.total_anual = result.total_anual
            state.total_assinantes = result.total_assinantes
        },

        ADICIONAR_CLIENTE(state, result) {
            state.clientes.push(result)
        },

        DELETAR_CLIENTE(state, index) {
            state.clientes.splice(index, 1)
        },

        EDITAR_CLIENTE(state, response) {
            state.clientes.splice(response.index, 1, response.dados)
        },

        STORE_TIPO_CONTA(state, result) {
            state.tipo_conta = result
        },

        STORE_TIPO_DATA(state, result) {
            state.tipo_data = result
        },

        STORE_FILTRO_DATA(state, payload) {
            state.data_inicio = payload.data_inicio
            state.data_final = payload.data_final
        },

        STORE_TIPO_BUSCA(state, payload) {
            if (payload.tipo_busca === 'ID') {
                state.tipo_busca = 'empresas.id'
            } else if (payload.tipo_busca === 'Nome') {
                state.tipo_busca = 'empresas.nome'
            } else if (payload.tipo_busca === 'E-mail Empresa') {
                state.tipo_busca = 'empresas.email'
            } else state.tipo_busca = 'empresas.email'
            
            state.input_busca = payload.filter
        },

        STORE_BUSY(state, result) {
            state.busy = result
        },
        STORE_PAGINACAO(state, page){
            state.page = page
        },

    },

    actions: {

        listaClientes(context) {
            context.commit('STORE_BUSY', true)

            axios('adm/administracao/clientes' +'?tipo_conta='+context.state.tipo_conta+ '&tipo_data=' + context.state.tipo_data + '&data_inicio=' + context.state.data_inicio + '&data_final=' + context.state.data_final + '&tipo_busca='+context.state.tipo_busca+'&input_busca='+context.state.input_busca+'&page='+context.state.page).then(res => {
                context.commit("STORE_CLIENTES", res.data)
                context.commit('STORE_BUSY', false)
            })
            
        },
    }

}