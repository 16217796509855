import axios from 'axios'
import moment from 'moment'

export default {

    namespaced: true,

    state:{
        atendimentos: [],
    },

    mutations:{
        STORE_ATENDIMENTOS(state, result){
            state.atendimentos = result
        },

        DELETAR_ATENDIMENTO(state, index){
            state.atendimentos.splice(index, 1)
        },

        ADICIONAR_ATENDIMENTO(state, result) {
            state.atendimentos.push(result)
        },

        EDITAR_ATENDIMENTO(state, response) {
            state.atendimentos.splice(response.index, 1, response.dados)
        },

    },

    actions:{

        listaAtendimentosAbertos(context, id){

            axios('adm/atendimento/dashboard/'+id).then(res => {
                context.commit("STORE_ATENDIMENTOS", res.data)
            })
        }

    }


}
