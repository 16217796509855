import Cookie from 'js-cookie'

const TOKEN_NAME = 'token';

export default {

    setToken (token)  {

        Cookie.set(TOKEN_NAME, token)
        Cookie.set('expiration_token', 30);
    },

    getToken(){

        return Cookie.get(TOKEN_NAME);
    },

    deleteToken(){

        Cookie.remove(TOKEN_NAME);

    },

    setLoginUniqueToken(token){

        Cookie.set('confirmation_token', token);

    },


    getLoginUniqueToken($token){

        return Cookie.get('confirmation_token');

    },

    deleteLoginUniqueToken($token){

        Cookie.remove('confirmation_token');

    },
}
