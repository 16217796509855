
import Guard from '../services/auth'

export default [

    {
        path: '/adm-clientes', name: 'adm-clientes',
        component: () => import('@/views/administracao/clientes/ClientesPrincipal.vue'),
        beforeEnter: Guard.redirectIfNotAuthenticated,
        meta: {pageTitle: 'Administração', breadcrumb: [{ text: 'Clientes',active: true }], permissoes: 1},
    },
    {
        path: '/adm-cadastro-teste', name: 'adm-cadastro-teste',
        component: () => import('@/views/administracao/cadastro-teste/CadastroTestePrincipal.vue'),
        beforeEnter: Guard.redirectIfNotAuthenticated,
        meta: {pageTitle: 'Administração', breadcrumb: [{ text: 'Cadastro Teste',active: true }], permissoes: 1},
    },
    {
        path: '/adm-usuarios-cliente', name: 'adm-usuarios-cliente',
        component: () => import('@/views/administracao/usuarios-cliente/UsuariosClientePrincipal.vue'),
        beforeEnter: Guard.redirectIfNotAuthenticated,
        meta: {pageTitle: 'Administração', breadcrumb: [{ text: 'Usuários',active: true }], permissoes: 1},
    },


]
