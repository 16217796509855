import axios from 'axios'
import moment from 'moment'
import Vue from 'vue'
import getDate from '../../services/data'

export default {

    namespaced: true,

    state: {
        despesas: [],
        filtroStatusDespesa: 0,
        filtroDataInicial: moment().startOf("month").format("YYYY-MM-DD"),
        filtroDataFinal: moment().endOf("month").format("YYYY-MM-DD"),
        despesasFiltradas: [],
        despesasFiltradasData: [],
        totalAPagar: 0,
        totalAtrasados: 0,
        totalPagos: 0,
        filtroFornecedor: 0,
        busy: false,
    },

    mutations: {

        STORE_DESPESAS(state, payload) {
            state.despesas = payload.despesas
            state.totalAPagar = payload.total_pagar
            state.totalAtrasados = payload.total_atrasados
            state.totalPagos = payload.total_pago
        },

        DELETAR_DESPESA(state, index) {
            state.despesas.splice(index, 1)
        },

        ADICIONAR_DESPESA(state, payload) {
            if (payload) {
                state.despesas.push(payload)
            }
        },

        EDITAR_DESPESA(state, response) {
            let index = state.despesas.despesas.indexOf(response);

            state.despesas.despesas.splice(index, 1, response)
        },

        STORE_DESPESAS_FILTRADAS(state, result) {
            state.filtroStatusDespesa = result

        },

        STORE_DESPESAS_FILTRADAS_DATAS(state, result) {
            state.filtroDataInicial = result.data_inicio
            state.filtroDataFinal = result.data_final

        },

        STORE_PAGAR_DESPESA(state, payload) {
            let index = state.despesas.indexOf(payload);
            state.despesas.splice(index, 1, payload)
        },

        STORE_DESPESAS_FORNECEDOR(state, result) {
            state.filtroFornecedor = result
          },

        STORE_BUSY(state, result) {
            state.busy = result
        }

    },

    actions: {
        listaDespesas(context) {
            context.commit('STORE_BUSY', true)
            axios(`adm/despesas/?fornecedor=${context.state.filtroFornecedor}&data_inicio=${context.state.filtroDataInicial}&data_final=${context.state.filtroDataFinal}`).then(res => {
                context.commit("STORE_DESPESAS", res.data)
                context.commit('STORE_BUSY', false)
            })
        },
    },
}

