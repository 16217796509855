import axios from 'axios'

export default {

    namespaced: true,

    state: {
        teste_gratis: [],
        assinantes: []
    },

    mutations: {
        STORE_ASSINATURAS(state, result) {
            state.teste_gratis = []
            state.assinantes = []
            for (const key in result) {
                state.teste_gratis.push(result[key].teste_gratis);
                state.assinantes.push(result[key].assinantes);
            }
        },
    },

    actions: {

        listaAssinaturas(context, payload) {
            axios('adm/dashboard/teste-gratis-x-assinantes?ano=' + payload).then(res => {
                context.commit("STORE_ASSINATURAS", res.data)
            })
        }

    }

}