
import Guard from '../services/auth'

export default [
  {
    path: '/financeiro', name: 'plano',
    component: () => import('@/views/financeiro/ListaPlanos.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    meta: { pageTitle: 'Financeiro', breadcrumb: [{ text: 'Plano de Contas', active: true }], permissoes: 4 },
  },

  {
    path: '/financeiro-receitas', name: 'financeiro-receitas',
    component: () => import('@/views/financeiro/receitas/ReceitasPrincipal.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    meta: { pageTitle: 'Financeiro', breadcrumb: [{ text: 'Receitas', active: true }], permissoes: 4 },
  },

  {
    path: '/financeiro/contas-a-pagar', name: 'financeiro-despesas',
    component: () => import('@/views/financeiro/despesas/DespesasPrincipal.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    meta: { pageTitle: 'Financeiro', breadcrumb: [{ text: 'Despesas', active: true }], permissoes: 4 },


  },

  {
    path: '/financeiro/contas', name: 'financeiro-contas',
    component: () => import('@/views/financeiro/contas/ContasPrincipal.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    meta: { pageTitle: 'Financeiro', breadcrumb: [{ text: 'Contas', active: true }], permissoes: 4 },
  },

  {
    path: '/financeiro/pedidos', name: 'financeiro-pedidos',
    component: () => import('@/views/financeiro/pedidos-retirada/PedidosPrincipal.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    meta: { pageTitle: 'Financeiro', breadcrumb: [{ text: 'Pedidos de Retirada', active: true }], permissoes: 4 },
  },
  {
    path: '/financeiro/dre', name: 'financeiro-dre',
    component: () => import('@/views/financeiro/dre/dre.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    meta: { pageTitle: 'Financeiro', breadcrumb: [{ text: 'DRE', active: true }], permissoes: 4 },
  },
  {
    path: '/financeiro/nfse', name: 'financeiro-nfse',
    component: () => import('@/views/financeiro/nota-fiscal/NotaFiscalPrincipal.vue'),
    beforeEnter: Guard.redirectIfNotAuthenticated,
    meta: { pageTitle: 'Financeiro', breadcrumb: [{ text: 'Nota Fiscal', active: true }], permissoes: 4 },
  },

]
