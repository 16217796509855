
export default {
    getMessage(error, message) {
        if (error) {
            const genericMessage = 'Ocorreu um problema inesperado. Tente novamente mais tarde ou entre em contato com o suporte.'

            if (error.response && error.response.status >= 500) {
                return {
                    hasMessage: true,
                    message: genericMessage,
                    messageArray: error.response?.data?.errors ?? [],
                    color: 'danger'
                }
            }
            return {
                hasMessage: true,
                message: message ?? error.response?.data?.message ?? genericMessage,
                messageArray: error.response?.data?.errors ?? [],
                color: 'danger'
            }
        } else {
            return {
                hasMessage: true,
                message: message,
                messageArray: '',
                color: 'success'
            }
        }
    }
}
