
import Guard from '../services/auth'

export default [

    {
        path: '/cadastros-fornecedores', name: 'cadastros-fornecedores',
        component: () => import('@/views/cadastros/fornecedores/ListaFornecedores.vue'),
        beforeEnter: Guard.redirectIfNotAuthenticated,
        meta: {pageTitle: 'Cadastros', breadcrumb: [{ text: 'Fornecedores',active: true }], permissoes: 4},
    },

    {
        path: '/cadastros-assinaturas', name: 'cadastros-assinaturas',
        component: () => import('@/views/cadastros/assinaturas/ListaAssinaturas.vue'),
        beforeEnter: Guard.redirectIfNotAuthenticated,
        meta: {pageTitle: 'Cadastros', breadcrumb: [{ text: 'Assinaturas',active: true }], permissoes: 5},
    },

    {
        path: '/cadastros-cupons', name: 'cadastros-cupons',
        component: () => import('@/views/cadastros/cupons/CuponsPrincipal.vue'),
        beforeEnter: Guard.redirectIfNotAuthenticated,
        meta: {pageTitle: 'Cadastros', breadcrumb: [{ text: 'Cupons',active: true }], permissoes: 4},
    },


]
