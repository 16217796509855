/**
 * função addDias(data: se for nulo pega data do dia , dias)
 * função subDias(data: se for nulo pega data do dia , dias)
 * **/

import moment from 'moment'

export default {

  dataHoje() {
    let data = new Date()
    return moment([data.getFullYear(), data.getMonth(), data.getDate()])

  },

  hoje() {
    return this.dataHoje().format('YYYY-MM-DD')
  },

  diaHoje() {
    let data = new Date()
    return data.getDate()
  },

  dataHoraHoje() {
    let data = new Date()
    let dt = `${data.getFullYear()}-${data.getMonth()}-${data.getDate()} ${data.getHours()}:${data.getMinutes()}:${data.getSeconds()}`

    return moment(dt).format('YYYY-MM-DD HH:mm:ss')
  },


  dataProximoDia(dia) {
    let hoje = new Date()
    if (hoje.getDate() > dia)
      return moment([hoje.getFullYear(), hoje.getMonth(), dia]).add(1, 'month')

    return moment([hoje.getFullYear(), hoje.getMonth(), dia])
  },

  proximoDia(dia) {
    return this.dataProximoDia(dia).format('YYYY-MM-DD')
  },

  dataMaisAntiga(strData1, strData2) {
    let dt1 = new Date(strData1);
    let dt2 = new Date(strData2);

    return dt1 < dt2;
  },

  addDias(data, dias) {

    let resultado = ''

    if (!data) {

      resultado = this.dataHoje().add((dias), 'day')

    } else {

      let d = this.converteData(data)
      resultado = d.add((dias), 'day')

    }

    return resultado.format('YYYY-MM-DD')

  },

  subDias(data, dias) {

    let resultado = ''

    if (!data) {

      resultado = this.dataHoje().subtract((dias), 'day')

    } else {

      let d = this.converteData(data)

      resultado = d.subtract((dias), 'day')

    }

    return resultado.format('YYYY-MM-DD')


  },

  addMes(data, mes) {

    let resultado = ''

    if (!data) {

      resultado = this.dataHoje().add((mes), 'month')

    } else {
      let d = this.converteData(data)
      resultado = d.add((mes), 'month')

    }

    return resultado.format('YYYY-MM-DD')

  },

  subMes(data, mes) {

    let resultado = ''

    if (!data) {

      resultado = this.dataHoje().subtract((mes), 'month')

    } else {
      let d = this.converteData(data)
      resultado = d.subtract((mes), 'month')

    }

    return resultado.format('YYYY-MM-DD')

  },

  converteData(data) {

    return moment(data)

  },

  primeiroDiaMes() {
    const data = new Date()

    return data.getFullYear() + '-' + (data.getMonth() + 1) + '-01'
  },

  ultimoDiaMes() {
    const date = new Date();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let data = JSON.stringify(lastDay)
    data = data.slice(1,11)
    return data
  },

  mesAtual() {
    let data = new Date()
    return data.getMonth() + 1

  },

  anoAtual() {
    let data = new Date()
    return data.getFullYear()

  },

  getAnoFiltros() {
    let result = []
    const data = new Date()
    let dataInicio = 2017
    const anoAtual = (data.getFullYear() + 1)


    while (dataInicio <= anoAtual) {

      result.push({
        value: dataInicio, nome: dataInicio
      })
      dataInicio += 1
    }

    return result

  }

}
