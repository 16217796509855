import axios from 'axios'
import Vue from 'vue'
import getDate from '../../services/data'

export default {

  namespaced: true,

  state: {
    cupons: [],
  },

  mutations: {

    STORE_CUPONS(state, payload) {
      state.cupons = payload
    },

    DELETAR_CUPOM(state, index) {
      state.cupons.splice(index, 1)
    },

    ADICIONAR_CUPOM(state, payload) {
      state.cupons.push(payload)
    },

    EDITAR_CUPOM(state, response) {
      state.cupons.splice(response.index, 1, response.dados)
    },

  },

  actions: {
    listaCupons(context) {
      axios('adm/cupons').then(res => {
        context.commit("STORE_CUPONS", res.data)
    })
    }
  }
}
