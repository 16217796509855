import axios from 'axios'
import moment from 'moment'

export default {

    namespaced: true,

    state:{
        cadastros: [],
        data_inicio: moment().startOf("month").format("YYYY-MM-DD"),
        data_final: moment().endOf("month").format("YYYY-MM-DD"),
        tipo_confirmado: '',
        busca_email: '',
        page: 1,
        confirmados: 0,
        nao_confirmados: 0
    },

    mutations:{
        STORE_CADASTROS(state, result){
            state.cadastros = result.dados
            state.confirmados = result.quantidadeConfirmados
            state.nao_confirmados = result.quantidadeNaoConfirmados
        },

        DELETAR_CADASTROSO(state, index){
            state.cadastros.splice(index, 1)
        },

        ADICIONAR_CADASTROS(state, result) {
            state.cadastros.push(result)
        },

        EDITAR_CADASTROS(state, response) {
            state.cadastros.splice(response.index, 1, response.dados)
        },

        STORE_FILTRO_DATA(state, payload) {
            state.data_inicio = payload.data_inicio
            state.data_final = payload.data_final
        },
        STORE_TIPO_CONFIRMADO(state, result){
            state.tipo_confirmado = result
        },
        STORE_BUSCA_EMAIL(state, result){
            state.busca_email = result
        },
        STORE_PAGINACAO(state, page){
            state.page = page
        },

    },

    actions:{

        listaCadastros(context){
            axios('adm/administracao/cadastro-teste/?tipo_confirmado='+context.state.tipo_confirmado+'&page='+context.state.page +'&data_inicio=' + context.state.data_inicio + '&data_final=' + context.state.data_final + '&busca_email=' + context.state.busca_email ).then(res => {
                context.commit("STORE_CADASTROS", res.data)
            })
        }

    }


}
