import axios from 'axios'
import moment from 'moment'

export default {
    namespaced: true,
    state: {
        notas: [],
        filtros: {
            status: '',
            sistema: 2,
            nfiscal_id: '',
            id_company: '',
            data_inicial: moment().startOf("month").format("YYYY-MM-DD"),
            data_final: moment().endOf("month").format("YYYY-MM-DD"),
        },
    },
    mutations: {
        STORE_NOTAS(state, result) {
            state.notas = result
        },
        STORE_FILTROS(state, payload) {
            state.filtros.data_inicial = payload.data_inicial;
            state.filtros.data_final = payload.data_final;
            state.filtros.id_company = payload.id_company;
            state.filtros.nfiscal_id = payload.nfiscal_id;
            state.filtros.status = payload.status;
            state.filtros.sistema = payload.sistema;
        },
    },
    actions: {
        async listaNotas(context) {
            const params = {
                data_ini: context.state.filtros.data_inicial,
                data_fin: context.state.filtros.data_final,
                id_company: context.state.filtros.id_company,
                nfiscal_id: context.state.filtros.nfiscal_id,
                status: context.state.filtros.status,
                sistema: context.state.filtros.sistema,
            };

            const response = await axios.get('adm/nfse', {params});
            context.commit("STORE_NOTAS", response.data);
        },
    }
}
