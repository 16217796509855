import axios from 'axios'
import moment from 'moment'

export default {

    namespaced: true,

    state: {
        assinaturas: [],
        recorrencia_assinatura: 0,
        tipo_data: 'data_inicio',
        tipo_assinatura: 0,
        id_company: '',
        data_inicial: moment().startOf("month").format("YYYY-MM-DD"),
        data_final: moment().endOf("month").format("YYYY-MM-DD"),
    },

    mutations: {
        STORE_ASSINATURAS(state, result) {
            state.assinaturas = result
        },

        STORE_TIPO_ASSINATURA(state, result) {
            state.tipo_assinatura = result
        },

        STORE_RECORRENCIA_ASSINATURA(state, result) {
            state.recorrencia_assinatura = result
        },

        STORE_TIPO_DATA(state, result) {
            state.tipo_data = result
        },

        STORE_FILTRO_DATA(state, payload) {
            state.data_inicial = payload.data_inicial
            state.data_final = payload.data_final
        },

        STORE_ID_COMPANY(state, result) {
            state.id_company = result
        },

    },

    actions: {

        listaAssinaturas(context) {
            axios('adm/notificacoes' +'?tipo_assinatura='+context.state.tipo_assinatura+ '&tipo_data=' + context.state.tipo_data + '&data_inicial=' + context.state.data_inicial + '&data_final=' + context.state.data_final + '&id_company='+context.state.id_company + '&recorrencia_assinatura='+context.state.recorrencia_assinatura).then(res => {
                context.commit("STORE_ASSINATURAS", res.data)
            })
            
        },
    }

}