
import Guard from '../services/auth'

export default [
    {
        path: '/enviar-mensagem', name: 'enviar-mensagem',
        component: () => import('@/views/mensagens/EnviarMensagemPrincipal.vue'),
        beforeEnter: Guard.redirectIfNotAuthenticated,
        meta: {pageTitle: 'Mensagens', breadcrumb: [{ text: 'Enviar Mensagem',active: true }], permissoes: 0},
    },

]
